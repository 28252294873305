import React from "react";
// react plugin used to create switch buttons


// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components

function StangBlog() {

    return (
        <>
            <div>

                <Container>

                    <Container>
                        
                        <Row>
                        
                        <Col md="6">
                        <Container>
                                    
                                    <img
                                        src={require("assets/img/PHOTO-2024-08-04-16-48-23.jpg")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                                </Container>
                                <br/> <br/>
                        
                        </Col>    
                        <Col md="6">
                                    <h3
                                    style={{
                                        fontFamily:"Mustang4",
                                        fontSize:"20px",
                                        textAlign:"center"
                                    }}>
                                        Meet de Agosto
                                    </h3>
                                    
                                    <p>
                                        Se realizó el Meet mensual del Mustang Club Guatemala, el día 4 de agosto 
                                        de 2024, El punto de partida fue la gasolinera Puma del Colegio Italiano
                                        y nuestro destino, el restaurante Hacienda Real en Tecpan.
                                        Fue un meet en donde familias viajaron a disfrutar de un verdadero paseo en los Mustangs, también
                                        tuvimos nuevas incorporaciones al Club. 
                                        
                                       
                                    </p>

                                    
                            </Col>
                            <br/> <br/>
                            <Col md="4">
                            <img
                                        src={require("assets/img/7.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    <br/><br/>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/8.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                                               <br/> <br/>    <br/> <br/>
                            <Col md="4">
                            <img
                                        src={require("assets/img/9.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            
                            <Col md="4">
                            <img
                                        src={require("assets/img/10.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
                            
                            <Col md="4">
                            <img
                                        src={require("assets/img/11.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/12.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
         
                            <hr/>
                           

                        <Col md="6">
                                <Container>
                                    <br/><br/>
                                    <img
                                        src={require("assets/img/WhatsApp Image 2024-05-30 at 21.06.40.jpeg")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                                </Container>
                                
                                <br/> <br/>

                            </Col>
                            

                            <Col md="6">
                            <br/><br/>
                                    <h3
                                    style={{
                                        fontFamily:"Mustang4",
                                        fontSize:"20px",
                                        textAlign:"center"
                                    }}>
                                        Meet de Junio
                                    </h3>
                                    
                                    <p>
                                        Se realizó el Meet mensual del Mustang Club Guatemala, el día 23 de junio 
                                        de 2024, El punto de partida fue la gasolinera Puma del Colegio Italiano
                                        y nuestro destino, el restaurante Casa Cristal dentro del Hotel Villa Bokéh en Antigua Guatemala.
                                        Fue un meet lleno de alegría, tanto para el club, como para todas las personas que apreciaron la 
                                        estampida en la ciudad colonial, se exhibieron los autos en el parque central y se hizo un recorrido
                                        de demostración por las calles de Antigua.
                                        
                                       
                                    </p>
                                
                                    
                            </Col>
                            <br/> <br/>
                            <Col md="4">
                            <img
                                        src={require("assets/img/1.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    <br/><br/>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/2.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                                               <br/> <br/>    <br/> <br/>
                                               
                            <Col md="4">
                            <img
                                        src={require("assets/img/3.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            
                            <Col md="4">
                            <img
                                        src={require("assets/img/4.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
                            
                            <Col md="4">
                            <img
                                        src={require("assets/img/5.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/6.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
         
                            <hr/>
                           
                            <Col md="6">
                        
                                <Container>
                               
                                <br/><br/>
                                    <img
                                        src={require("assets/img/Entrada1.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                                </Container>
                                <br/> <br/>
                             </Col>
                            

                            <Col md="6">
                            <br/><br/>
                                    <h3
                                    style={{
                                        fontFamily:"Mustang4",
                                        fontSize:"20px",
                                        textAlign:"center"
                                    }}>
                                        Meet de Abril
                                    </h3>
                                    <p>
                                        Se realizó el Meet mensual del Mustang Club Guatemala, el día 21 de abril 
                                        de 2024, con motivo del 60 aniversario del Ford Mustang, el cuál tuvo como 
                                        punto de partida la gasolinera Puma frente a Próceres
                                        y nuestro destino fue el Restaurante El Bambú, Km 27 Carretera a El Salvador.
                                        Esta meet fue muy especial porque se unieron personas desde Puerto Barrios, sin duda
                                        este grupo sigue creciendo, superando las fronteras de los departamentos. 
                                        <br/>
                                        Te compartimos algunas fotografías del meet:
                                    </p>

                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada7.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    <br/><br/>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada2.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada3.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada6.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada10.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="4">
                            <img
                                        src={require("assets/img/Entrada9.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                    
                            </Col>
                         <Col md="12">
                            <br/>
                         <hr/>
                         </Col>
                            <Col md="6">
                                
                                <Container>
                                    
                                <br/><br/>
                                    <img
                                        src={require("assets/img/Entrada11.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                                </Container>
                                <br/> <br/>
                                
                            </Col>

                            <Col md="6">
                           
                                <br/><br/>
                                
                                    <h3
                                    style={{
                                        fontFamily:"Mustang4",
                                        fontSize:"20px",
                                        textAlign:"center"
                                    }}>
                                        Ganador en el Drive Fest Sunset
                                    </h3>
                                    
                                    <p>
                                        El domingo 21 de abril de 2024, se realizó el Drive Fest Sunset en el Parque de la Industria,
                                        contando con la participación de múltiples miembros del Club Mustang Guatemala.<br/>
                                        En la categoría Americano Avanzado, el Ford Mustang 2006 Modificado de Sebastian Sosa se llevó el primer
                                        lugar. Te compartimos algunas fotos de su Mustang expuesto y del trofeo ganado. 
                                        
                                    </p>

                            </Col>
                            
                            <Col md="6">
                            <img
                                        src={require("assets/img/Entrada12.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                           
                            <Col md="6">
                            <img
                                        src={require("assets/img/Entrada13.png")}
                                        style={{
                                           
                                        }}
                                    ></img>
                            </Col>
                            <Col md="6">
                                <Container>

                                </Container>

                            </Col>

                            <Col md="6">


                            </Col>



                        </Row>

                    </Container>


                </Container>

            </div>
            <br /><br />
        </>
    );
}

export default StangBlog;