import React from "react";
// react plugin used to create switch buttons


// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function BasicElements() {

  return (
    <>
      <div className>

          <h3
            style={{
              fontFamily: "Mustang2",
              textAlign: "center",
              fontSize: "25px"
            }}>¡Aviso Importante!</h3>



          <Container>
        

              <Col md="12">
                <h4>
                  Se ha reportado que existen una cuentas de diferentes redes sociales haciéndose pasar por el Club Mustang Guatemala para insultar a otras personas y clubes.
                
                  Club Mustang Guatemala recuerda que las únicas redes sociales oficiales son:
                  <br/>
         
                  <a href="https://www.facebook.com/share/hZMBbXcBeH67hrz9/?mibextid=qi2Omg">Mustang Club Guatemala en Facebook</a> y  
                  <a href="https://www.instagram.com/clubmustangguatemala?igsh=MTlkNTJkeWhxOWhobg%3D%3D"> @clubmustangguatemala en Instagram.</a>
                  <Col md="12">
                <img
                  src={require("assets/img/449092722_122152205132220970_874163442050176203_n.jpg")}

                ></img>
              </Col>
                  
                </h4>
              </Col>
    
              </Container>


        <Container>
          <h3
            style={{
              fontFamily: "Mustang2",
              textAlign: "center",
              fontSize: "25px"
            }}>¡Bienvenido al Club!</h3>
            
            
          <Container>
            <Row>

              <Col md="6">
                <h4>
                  Club Mustang Guatemala es un grupo de amantes del Ford Mustang con más de 15 años de historia.
                  La fraternidad entre los miembros de MCG es legendaria. 
                  <br/>
                  En cualquier parte del territorio guatemalteco
                  al que viajes encontrarás miembros del MCG que estarán dispuestos a
                  ayudarte con asistencia, asesoramiento y/o compañerísmo.
                  <br/>
                  Se parte del Club Mustang Guatemala y comparte con todos los entusiastas guatemaltecos
                  de uno de los autos más embematicos de la cultura automotriz.
                  
                </h4>
              </Col>
              
              <Col md="6">
                <img
                  src={require("assets/img/431312849_122115910838220970_6556204587851554749_n.jpg")}

                ></img>
              </Col>
              <Col md="12">
                            <br/>
                         <hr/>
                         </Col>
              <Col md="6">
                <br />
                <img
                  src={require("assets/img/Foto2.png")}

                ></img>
              </Col>
              <Col md="6">
                <br />
                <h3
                  style={{
                    fontFamily: "Mustang2",
                    fontSize: "19px",
                    textAlign: "center"
                  }}>
                  Historia del ford mustang
                </h3>
                <h4
                  style={{
                    fontSize: "18px"
                  }}>
                  El Ford Mustang es un automóvil deportivo muscle car de dos puertas, producido
                  por el fabricante estadounidense Ford Motor Company desde 1964, como modelo 1964½.
                  <br />
                  Originalmente fue concebido por Lee Iacocca como un concurso entre departamentos
                  como algo personal y deportivo.
                  <br />
                  La séptima generación del Ford Mustang fue presentada en 2023.
                </h4>
                <div
                  style={{
                    textAlign: "center"
                  }}><Button className="btn-round" color="info" type="button"
                    textAlign="center" href="/historia">
                    Conocer Más
                  </Button></div>
                  <Col md="12">
                           
                         <hr/>
                         </Col>
              
                <h3
                  style={{
                    fontFamily: "Mustang2",
                    fontSize: "19px",
                    textAlign: "center"
                  }}>
                  Historia del mustang club of america
                </h3>
                <h4
                  style={{
                    fontSize: "18px"
                  }}>
                  En marzo de 1976, Stan Jones, George White, Gary Goddard y Tom Taylor se reunen
                  para discutir la idea de formar un club para entusiastas
                  del Mustang. Se colocó un anuncio en el Atlanta Journal-Constitution esperando
                  congregar a la mayor cantidad de personas posibles.
                  <br />
                  En la primera reunión organizativa del mes de marzo asistieron un total de
                  diez personas. Un mes después, el 11 de abril, se celebró la primera reunión
                  oficial para establecer un Club Nacional Mustang.
                </h4>
                <div
                  style={{
                    textAlign: "center"
                  }}><Button className="btn-round" color="info" type="button"
                    textAlign="center" href="/mca">
                    Conocer Más
                  </Button></div>
              </Col>
            </Row>
          </Container>

         
        </Container>
      </div>
    </>
  );
}

export default BasicElements;
