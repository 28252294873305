import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col md="4">
              <h3
              style={{
                fontFamily:"Mustang2",
                fontSize:"30px",
                textAlign:"center"
              }}>¡Únete al Club!</h3>
              <h5 >
                Si eres dueño de un Ford Mustang de cualquier generación, o bien eres amante
                de la marca, escríbenos y se parte del Mustang Club Guatemala.
              </h5>
              <div
              style={{
                textAlign:"center"
              }}>
              <Button
                className="btn-round mr-1"
                color="info"
                href="/unete"
                role="button"
                size="lg"
                
              >
                Inscríbete
              </Button>
              </div>
            </Col>
            <Col md="8">
            <img
                  src={require("assets/img/432660107_122122959248220970_2125186545948181151_n.jpg")}

                ></img>
            </Col>
            
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="6">
            <h3
              style={{
                fontFamily:"Mustang4",
                fontSize:"30px",
                textAlign:"center"
              }}>Playlist Para tu mustang</h3>
              
              <iframe style={{
              borderRadius:"12px" 
            }}
            src="https://open.spotify.com/embed/playlist/2G4QU33TonJW7oXN2tcTO2?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>           <Col className="ml-auto mr-auto" md="6">
            </Col>
            </Col>
            <Col className="ml-auto mr-auto" md="6">
            <h3
              style={{
                fontFamily:"Mustang4",
                fontSize:"30px",
                textAlign:"center"
              }}>Drive With Jim Farley</h3>
              <iframe style={{
              borderRadius:"12px" 
            }}
            src="https://open.spotify.com/embed/episode/0PLjT1xlcHaojVX7scGDJk?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>           </Col>
          </Row>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3
              style={
                {
                  fontFamily:"Mustang4"
                }
              }>¡Síguenos en nuestras redes sociales!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-neutral btn-icon btn-round"
                color="instagram"
                href="https://www.instagram.com/clubmustangguatemala?igsh=MTlkNTJkeWhxOWhobg=="
                id="tooltip86114138"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip86114138">
                Síguenos
              </UncontrolledTooltip>
              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="https://www.facebook.com/profile.php?id=61556629112773"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                Da Like y Comparte
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
